@use "@/styles/colors.scss";
@use "@/styles/zindex.scss";

/// Remove the default styling of an element
/// Useful for clearing the browser default styles
/// for a <button>, <input>, or <a> tag
@mixin element-style-reset {
  // set properties to their initial or inherited values
  all: unset;
  // Keep the default focus outline for a11y
  outline: revert;
  box-sizing: border-box;
}

/// Will hide the content visually, yet provide the content to screen readers
@mixin visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/// Adds a focus ring around the element
/// Can be used to target different kinds of focus with an optional argument
/// @default value: `focus-visible`, which only shows the focus ring on tab key
///
/// e.g., `focus-within,` `focus-within:not(.some-class)`
///
/// ```
/// // basic usage targeting :focus
/// .your-class {
///   @include focused-element;
/// }
///
/// // targeting :focus-within
/// .your-class {
///   @include focused-element(:focus-within);
///
///   button {
///     @include element-style-reset;
///   }
/// }
/// ```
@mixin focused-element($pseudo-class: focus-visible) {
  & {
    transition:
      outline 150ms ease-in,
      box-shadow 150ms ease-in;
  }
  &:#{$pseudo-class} {
    border-color: transparent;
    outline: 2px solid colors.$color-border-selected;
    box-shadow:
      0 0 0 3px colors.$color-bkg-primary,
      0 0 0 6px rgba(colors.$color-bkg-selected, 0.9);
  }
}

/// Used for things like dropdowns, popovers and tooltips
@mixin elevated-container {
  z-index: zindex.$dropdown-level;
  border: 1px solid colors.$color-border;
  border-radius: 4px;
  background: colors.$color-bkg-primary;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}

/// Make overflow text appear as a "..."
@mixin text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/// Creates a dynamic grid layout with a minimum and maximum number of columns
///
/// @param {Number} $min-cols - The minimum number of columns
/// @param {Number} $max-cols - The maximum number of columns
/// @param {Number} $col-min-width - The minimum width of each column
/// @param {Number} $gap [0] - The gap between rows and columns
/// @param {Number} $row-gap [$gap] - The gap between rows. Defaults to `$gap`
/// @param {Number} $column-gap [$gap] - The gap between columns. Defaults to `$gap`
///
/// @example scss - make a grid container that is 3 - 5 columns wide, with a minimum column width of 200px
///   .grid {
///     @include grid-min-max-cols(3, 5, 200px);
///   }
///
/// @example scss - Naming an optional argument
///   .grid {
///     @include grid-min-max-cols(3, 5, 200px, $column-gap: 12px);
///   }
///
/// Exposes CSS variables that can be changed at runtime and consumed by children:
/// --min-cols
/// --max-cols
/// --col-min-width
/// --row-gap
/// --column-gap
///
/// @link https://stackoverflow.com/a/69154193 Stack Overflow Anwser with the original implementation and background on how this works
@mixin grid-min-max-cols(
  $min-cols,
  $max-cols,
  $col-min-width,
  $gap: 0,
  $row-gap: $gap,
  $column-gap: $gap
) {
  --min-cols: #{$min-cols};
  --max-cols: #{$max-cols};
  --col-min-width: #{$col-min-width};
  --row-gap: #{$row-gap};
  --column-gap: #{$column-gap};

  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(
      min(
        (
          100% / var(--min-cols) - var(--column-gap) * (var(--min-cols) - 1) /
            var(--min-cols)
        ),
        max(
          var(--col-min-width),
          (
            100% / var(--max-cols) - var(--column-gap) * (var(--max-cols) - 1) /
              var(--max-cols)
          )
        )
      ),
      1fr
    )
  );
  gap: var(--row-gap) var(--column-gap);
}

.test {
  @include grid-min-max-cols(1, 2, 200px, 10px);
}
