@use "@/styles/colors.scss";

$download-btn-width: 30rem;

.invitationDescription {
  max-width: $download-btn-width;
  text-align: center;
}

.applicantName {
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  margin-top: 26px;
  margin-bottom: 4px;
}

.assignedTeamMember {
  display: flex;
  align-items: center; // ensure the icon is inline with the text

  .nmlsNumber {
    margin-left: 16px;
  }
}

.downloadBtn {
  width: $download-btn-width;
  margin-top: 60px;
}

.noteSubtext {
  font-size: 12px;
  margin-top: 20px;
}
