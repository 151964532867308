.logo_svg {
  display: block;

  .loading {
    animation: loadingLine 1.5s cubic-bezier(0.25, 0.1, 0.25, 1) infinite;
  }
}

@keyframes loadingLine {
  0% {
    transform: translateX(0);
    width: 30%;
  }
  100% {
    transform: translateX(100%);
    width: 60%;
  }
}
