@use "sass:map";

/// Keep this map variables in sync with the breakpoint enum in src/hooks/useMediaQuery.ts
$breakpoint: (
  tablet: 701px,
  desktop: 1025px,
);

/// Adds styles that only get applied on screens larger than 700px
@mixin tablet-and-larger {
  @media only screen and (min-width: map.get($breakpoint, "tablet")) {
    @content;
  }
}

/// Adds styles that only get applied on screens larger than 1024px
@mixin desktop-and-larger {
  @media only screen and (min-width: map.get($breakpoint, "desktop")) {
    @content;
  }
}
