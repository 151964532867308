@use "@/styles/colors.scss";

.nav {
  margin-bottom: 32px;
}

.heading_h1 {
  font-size: 48px;
  font-weight: 400;
}
