@use "@/styles/colors.scss";
@use "@/styles/mixins";

.main {
  @include mixins.desktop-and-larger {
    padding: 40px;
    gap: 24px;
  }
  @include mixins.tablet-and-larger {
    padding: 16px;
  }

  display: flex;
  flex-direction: column;
  flex-grow: 1; // make sure main content takes up available space
  width: 100%;
  padding: 8px;
  gap: 16px;
}

.centered {
  align-items: center;
}
